import {axiosPost} from '@/helpers/axios-helper'

const PORT = '37000'

export const TRK = '' + Math.floor(Math.random() * 1000000)

export const apiUrlGet = () => {
  let url = window.location.origin || ''
  if (url.includes('localhost')) {
    url = 'http://localhost:'+PORT
  }
  url += '/api'
  console.log('apiUriGet', url, window.location.origin)
  return url
}

export const wsUrlGet = () => {
  let url = window.location.origin || ''
  if (url.includes('localhost')) {
    url = 'ws://localhost:'+PORT
  } else {
    url = 'wss:' + url.split(':')[1]
    url += '/api'
  }
  return url
}

export const visitCount = async (def) => {
  // console.log('api helper visit count', def)
  def.tk = TRK
  try {
    await axiosPost('visit-count', def).catch()
  } catch (err) {
    // do nothing
    console.error('visitCount ERROR', err.message)
  }
}
