
// noinspection UnnecessaryLocalVariableJS

const debug = false

function linky (text) {
  if (text.includes('mailto')) {
    const re = /mailto:(\S+@\S+)/g
    return text.replace(re, '<a href="$&" target="_blank">$1</a>')
  }
  const linkedText = text
    .replace(/\[(.*?)]\((.*?)\)/gm, '<a href="$2">$1</a>')
    .replace(/<http(.*?)>/gm, '<a href="http$1">http$1</a>')

  // const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[\/[a-zA-Z0-9()@:%_\-\+.~#?&=]*]*/g
  // const linkedText = text.replace(re,'<a href="$&" target="_blank">$&</a>')
  // if(debug) console.log('T2H linky', text, linkedText)
  return linkedText
}

function worker (el, binding, source) {
  if (debug) console.log('T2H directive source' , source, binding)
  // console.log('T2H directive el' , el)
  // console.log('T2H directive source' , source)
  // console.log('T2H directive binding' ,  binding)
  if (binding.value) {
    // if (debug) console.log('T2H directive binding', binding.value)
    el.innerHTML = textToHtml(binding.value, binding.modifiers.noAutoLink)
    if (debug) console.log('T2H el.innerHTML', el.innerHTML)
  } else {
    el.innerHTML = ''
  }
}

export function isObject ( possibleObj ) {
  return possibleObj && possibleObj.constructor.name === 'Object'
}
export function textToHtml (value, options={}) {
  const textVal = isObject(value) ? value.text : value
  const noAutoLink= isObject(value) ? value.noAutoLink : false // options.noAutoLink || false
  const css = isObject(value) ? value.css : undefined
  let sentences = textVal.split('\n')
  // if (debug) console.log('T2H sentences', sentences)
  let html = []
  let pOpen = '<p' + (css ? ' class="' + css + '"' : '') + '>'
  let seenOne = false
  html.push(pOpen)
  sentences.forEach(s => {
    if (seenOne) html.push('</p>' + pOpen)
    if (noAutoLink) {
      html.push(s)
    } else {
      html.push(linky(s))
    }
    seenOne = true
  })
  html.push('</p>')
  if (debug) console.log('T2H html', html)
  return html.join('\n')
}


export default (el, binding) => {
  if (debug) console.log('T2H directive bind')
  worker(el,binding, 'bind')
}

/*
export default {
  bind (el, binding) {
    if (debug) console.log('T2H directive bind')
    worker(el,binding, 'bind')
  },
  update (el, binding) {
    if (debug) console.log('T2H directive update')
    worker(el,binding, 'update')
  }

}
*/
