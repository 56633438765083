const state = {
  currency: 'ca',
  apiError: '',
  smallWindow: false,
  sysMessage: '',
}
const cost = {
  ca: '2.75',
  us: '2.00',
  eu: '1.90'
}
const symb = {
  ca: 'C$ ',
  us: 'U$ ',
  eu: 'E\u20AC '
}
const minMon = {
  ca: '100',
  us: '75',
  eu: '68'
}

const getters = {
  apiError: state => state.apiError,
  currency: state => state.currency,
  sysMessage: state => state.sysMessage,
  price: state => symb[state.currency] + cost[state.currency],
  minMon: state => symb[state.currency] + minMon[state.currency]
}

const actions = {
  setCurrencyUSD ( context ) {
    context.commit('_setCurrency', 'us')
  },
  setCurrencyCAD ( context ) {
    context.commit('_setCurrency', 'ca')
  },
  setCurrencyEU ( context ) {
    context.commit('_setCurrency', 'eu')
  },
  initialize: function ({ commit }) {
    commit('initialize')
  }
}

const mutations = {
  initialize: function (state) {
  },
  setApiData: (state, apiData) => {
    // console.log('System store set ApiData: ', apiData)
    state.apiData = apiData
  },
  setSystemMessage: (state, msg) => {
    state.sysMessage = msg
  },
  setApiError: (state, error) => {
    if (error && process.env.NODE_ENV !== 'test') {
      console.error('System setApiError:', error)
    }
    state.apiError = error
  },
  _setCurrency: (state, val) => {
    state.currency = val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
