
/*
Import font awesome icons
*/
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faInstitution,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBarcode,
  faBars,
  faBell,
  faBookOpen,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faClock,
  faCog,
  faCogs,
  faCopy,
  faCut,
  faDiceD20,
  faDownload,
  faEdit,
  faExternalLinkAlt,
  faEye,
  faFileMedical,
  faFilePdf,
  faFilePrescription,
  faFilter,
  faFolderPlus,
  faGraduationCap,
  faGlobe,
  faHammer,
  faHandHoldingMedical,
  faHandshake,
  faHeartbeat,
  faHospital,
  faHourglassEnd,
  faHourglassStart,
  faInfoCircle,
  faList,
  faLock,
  faMagic,
  faMedkit,
  faMinus,
  faNotesMedical,
  faPen,
  faPlus,
  faPrescription,
  faQuestion,
  faSearch,
  faSchool,
  faSignInAlt,
  faSignOutAlt,
  faStethoscope,
  faStickyNote,
  faStopwatch,
  faStopCircle,
  faSort,
  faSortUp,
  faSortDown,
  faTable,
  faTag,
  faThLarge,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUnlock,
  faUpload,
  faUser,
  faUserGraduate,
  faUserClock,
  faUsersCog,
  faUserFriends,
  faUserInjured
} from '@fortawesome/free-solid-svg-icons'

import { faFontAwesome, faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBookMedical } from '@fortawesome/free-solid-svg-icons/faBookMedical'

export function setupIcons (app) {
  library.add(
    faInstitution,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faBars,
    faBarcode,
    faBell,
    faBookMedical,
    faBookOpen,
    faChalkboardTeacher,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronUp,
    faCircle,
    faClock,
    faCog,
    faCogs,
    faCopy,
    faCut,
    faDiceD20,
    faDownload,
    faEdit,
    faExternalLinkAlt,
    faEye,
    faFileMedical,
    faFilePrescription,
    faFilePdf,
    faFilter,
    faFolderPlus,
    faGlobe,
    faGraduationCap,
    faHammer,
    faHandHoldingMedical,
    faHandshake,
    faHeartbeat,
    faHospital,
    faHourglassEnd,
    faHourglassStart,
    faInfoCircle,
    faList,
    faLock,
    faMagic,
    faSearch,
    faMedkit,
    faMinus,
    faNotesMedical,
    faPen,
    faPlus,
    faPrescription,
    faQuestion,
    faSchool,
    faSignInAlt,
    faSignOutAlt,
    faStethoscope,
    faStickyNote,
    faStopwatch,
    faStopCircle,
    faSort,
    faSortUp,
    faSortDown,
    faTable,
    faTag,
    faThLarge,
    faTimes,
    faTimesCircle,
    faTrash,
    faUndo,
    faUnlock,
    faUpload,
    faUser,
    faUserGraduate,
    faUsersCog,
    faUserClock,
    faUserFriends,
    faUserInjured,
  )
  library.add(
    faFontAwesome,
    faCanadianMapleLeaf
  )
  // IN CODE sample usage is:
  // fas-icon(class="fa", icon="graduation-cap")
  app.component('fas-icon', FontAwesomeIcon)
}

export const APP_ICONS = {
  activity: 'notes-medical',
  activityClose: 'hourglass-end',
  activityOpen: 'hourglass-start',
  barcode: 'barcode',
  classList: 'user-friends',
  configure: 'cogs',
  copy: 'copy',
  consumer: 'school',
  course: 'chalkboard-teacher',
  teacher: 'chalkboard-teacher',
  school: 'institution',
  download: 'download',
  edit: 'edit',
  // ehrPage: 'book-medical',
  exitToLms: 'sign-out-alt',
  externalLink: 'external-link-alt',
  file: 'file-medical',
  filter: 'filter',
  forceSubmit: 'lock',
  itemDetails: 'cog',
  retractSubmit: 'unlock',
  list: 'list',
  lobj: 'book-open',
  menu: 'bars',
  new: 'magic',
  save: 'check',
  signIn: 'sign-in-alt',
  signOut: 'sign-out-alt',
  seed: 'hand-holding-medical',
  search: 'search',
  returnToSeedList: 'list',
  rawEhr: 'th-large',
  question: 'question',
  sortAsc: 'sort-down',
  sortDesc: 'sort-up',
  sortNone: 'sort',
  stopwatch: 'stopwatch',
  stopCircle: 'stop-circle',
  student: 'user-graduate',
  table: 'table',
  tags: 'tag', // for filtering by tag applied to an object
  trash: 'trash',
  undo: 'undo',
  upload: 'upload',
  view: 'eye',

}
