import { createStore } from 'vuex'
import system from './modules/system'
import visit from '@/store/modules/visit'
import demoStore from '@/store/modules/demoStore'

export const store = createStore({
  modules: {
    demoStore,
    system,
    visit
  }
})

export function initializeStore (store) {
  // console.log('Initialize vuex modules')
  // store.dispatch('authStore/initialize')
  store.dispatch('demoStore/initialize')
  store.dispatch('system/initialize')
  // store.dispatch('visit/initialize')
}
