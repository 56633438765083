import {visitCount} from '@/helpers/api-helper'
import {store} from '@/store'
function perfExit (perfStat) {
  perfStat.elapsed.loading = performance.now() - perfStat.start.loading
  return perfStat
}
function utmValue (toRoute, visitDef, utmKey, dbKey) {
  let value = toRoute.query[utmKey]
  if (value) {
    sessionStorage.setItem(utmKey, value)
  }
  value = sessionStorage.getItem(utmKey)
  if (value) {
    visitDef[dbKey] = value
  }
}

async  function onPageChange (toRoute, fromRoute) {
  // const routeName = toRoute.name
  const perfStat = { start: {}, elapsed: {} }
  perfStat.start.loading = performance.now()
  console.log('Pg', toRoute.query)
  let visitDef =       {
    pageTo: toRoute.fullPath,
    pageFrom: fromRoute.path,
    hash: toRoute.hash,
    type: 'PAGE',
    queryStr: JSON.stringify(toRoute.query)
  }
  utmValue(toRoute, visitDef, 'utm_source', 'utmSource')
  utmValue(toRoute, visitDef, 'utm_content', 'utmContent')

  console.log(visitDef)

  const curr = toRoute.query.curr
  console.log('Currency = ', curr)

  switch (curr) {
  case 'ca':
    store.dispatch('system/setCurrencyCAD')
    break
  case 'us':
    store.dispatch('system/setCurrencyUSD')
    break
  case 'eu':
    store.dispatch('system/setCurrencyEU')
    break
  }


  /*
  Note that the browser settings can override the OS setting
   */
  const isDark = window.matchMedia('(prefers-color-scheme:dark)').matches
  console.log('isDark', isDark)

  try {
    perfStat.start.loadApi = performance.now()
    // console.log('TO DO load api data')
    await visitCount(visitDef)

  } catch (err) {
    console.error('PageController err', err)
    if (window.location.origin.includes('localhost')) console.error('stack:', err.stack)
  } finally {
  }
  return perfExit(perfStat)
}

export default  onPageChange
