import { createApp, } from 'vue'
import {router} from './router'
import {store} from './store'
import '@/scss/styles.scss'
import layoutPublic from './layouts/LayoutPublic.vue'
import App from './App.vue'
import onPageChange from '@/helpers/page-controller'
import textToHtml  from '@/directives/text-to-html'
import dragDirective from '@/directives/drag-directive'
import {setupIcons} from '@/icons'
const LOCALHOST = window.location.origin.includes('localhost')

router.afterEach(async (to, from) => {
  let startTime = performance.now()
  const perfStat = await onPageChange(to, from)
  let elapsedTime = performance.now() - startTime
  console.log('page change elapsed', elapsedTime, JSON.stringify(perfStat.elapsed))
})
if (LOCALHOST) {
  window.addEventListener('storage', (event) => {
    console.log('----------------- Hey Dev, this is listener on changes to local storage', event)
    // TODO consider that this event listener does not fire on the window that changed the local storage
    // But it does fire on other tabs.
    // Could be used to (a) open a second window for instructors to select the student to evaluate and
    // use listener like this in the main window to advance to the next student when local storage changes.
  })
}

const app = createApp(App)
app.use(store)
app.use(router)
app.directive('dragged', dragDirective)
app.directive('text-to-html', textToHtml)
app.component('layout-public', layoutPublic)
setupIcons(app)
app.mount('#app')
