import {createRouter, createWebHistory} from 'vue-router'
import LandingView from '@/views/LandingView.vue'
import {visitCount} from '@/helpers/api-helper'

export const ZONE_ADMIN = 'admin'
export const ZONE_PUBLIC = 'public'
export const ZONE_DEMO = 'demo'
export function routeIsPublic (route) {
  return route.meta.zone === ZONE_PUBLIC
}

let routes = [
  {
    path: '/',
    name: 'home',
    component: LandingView,
    meta: { layout: 'public', label: 'Home', zone: 'public' }
  },
]
routes.push({
  path: '/abcdefgh/vc',
  name: 'visitCount',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/VisitCountView.vue'),
  meta: { layout: 'public', label: 'Visit Count', zone: 'public' }
})

routes.push({
  path: '/about',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/AboutView.vue'),
  meta: { layout: 'public', label: 'About', zone: 'public' }
})
routes.push({
  path: '/faq',
  name: 'faq',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/Faq.vue'),
  meta: { layout: 'public', label: 'FAQ', zone: 'public' }
})
routes.push({
  path: '/product-features',
  name: 'product-features',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/ProductFeatures.vue'),
  meta: { layout: 'public', label: 'Product Features', zone: 'public' }
})
routes.push({
  path: '/product-details',
  name: 'product-details',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/ProductDetails.vue'),
  meta: { layout: 'public', label: 'Product Details', zone: 'public' }
})
routes.push({
  path: '/product-highlights',
  name: 'product-highlights',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/ProductHighlightsView.vue'),
  meta: { layout: 'public', label: 'Product Highlights', zone: 'public' }
})
routes.push({
  path: '/our-team',
  name: 'ourTeam',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/OurTeam.vue'),
  meta: { layout: 'public', label: 'Our Team', zone: 'public' }
})
routes.push({
  path: '/start',
  name: 'start',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/GetStartedView.vue'),
  meta: { layout: 'public', label: 'Get Started', zone: 'public' }
})
routes.push({
  path: '/webinars',
  name: 'webinars',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/WebinarsView.vue'),
  meta: { layout: 'public', label: 'Webinars', zone: 'public' }
})
routes.push({
  path: '/demo-ehr-only',
  name: 'demoEhrOnly',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/DemoEhrOnlyView.vue'),
  meta: { layout: 'public', label: 'Demo Register', zone: 'public' }
})
routes.push({
  path: '/demo-register',
  name: 'demoRegister',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/DemoRegisterView.vue'),
  meta: { layout: 'public', label: 'Demo Register', zone: 'public' }
})
routes.push({
  path: '/demo-go',
  name: 'demoGo',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/DemoGoView.vue'),
  meta: { layout: 'public', label: 'Demo', zone: 'public' }
})
routes.push({
  path: '/shop-register',
  name: 'shopRegister',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/BuyRegisterView.vue'),
  meta: { layout: 'public', label: 'Shop Register', zone: 'public' }
})
routes.push({
  path: '/shop-success',
  name: 'shopGo',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/BuySuccessView.vue'),
  meta: { layout: 'public', label: 'Shop Success', zone: 'public' }
})
routes.push({
  path: '/pricing',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/PricingViewV3.vue'),
  meta: { layout: 'public', label: 'Services', zone: 'public' }
})
routes.push({
  path: '/pricingV2',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/PricingViewV2.vue'),
  meta: { layout: 'public', label: 'Pricing', zone: 'public' }
})
routes.push({
  path: '/privacy',
  name: 'privacy',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/Privacy.vue'),
  meta: { layout: 'public', label: 'Privacy', zone: 'public' }
})
routes.push({
  path: '/terms',
  name: 'terms',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/TermsP.vue'),
  meta: { layout: 'public', label: 'Terms and Conditions', zone: 'public' }
})
routes.push({
  path: '/testimonials',
  name: 'Testimonials',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/Testimonials.vue'),
  meta: { layout: 'public', label: 'Testimonials', zone: 'public' }
})
routes.push({
  path: '/contact',
  name: 'contact',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/ContactView.vue'),
  meta: { layout: 'public', label: 'Contact', zone: 'public' }
})
routes.push({
  path: '/news-updates',
  name: 'newsUpdates',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/NewsUpdates.vue'),
  meta: { layout: 'public', label: 'News and Updates', zone: 'public' }
})
routes.push({
  path: '/news-article',
  name: 'newsArticle',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/NewsArticle.vue'),
  meta: { layout: 'public', label: 'News Article', zone: 'public' }
})
routes.push({
  path: '/contact-success',
  name: 'contactSuccess',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/ContactSuccessView.vue'),
  meta: { layout: 'public', label: 'Contact Success', zone: 'public' }
})
routes.push({
  path: '/SimExpo2023',
  name: 'simexpo2023',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/SimExpo2023.vue'),
  meta: { layout: 'public', label: 'SimExpo 2023', zone: ZONE_PUBLIC }
})
routes.push({
  path: '/imsh2024',
  name: 'imsh2024',
  component: () =>
    import(/* webpackChunkName: "chunk-[request][index]" */ './views/Imsh2024.vue'),
  meta: { layout: 'public', label: 'IMSH 2024', zone: ZONE_PUBLIC }
})

/*
Be careful to use /info exclusively as a banner landing site.
Eventually we can add parameters, if needed.
 */
routes.push({
  path: '/info', name: 'Info', redirect: (to) => {
    visitCount({pageTo: '/', pageFrom: 'info', type: 'LANDING'})
    return '/'
  }
})

routes.push({
  path: '/:pathMatch(.*)*',
  component: () =>
    import(/* webpackChunkName: "notfound" */ './views/PageNotFound.vue'),
  meta: { layout: 'outside', label: 'Page Not Found', zone: 'public' }
})

// import { outside } from './outsideRoutes'
// routes = routes.concat(outside())

/*
LAST ROUTE HERE FOR 4040
 */
routes.push(
  { path: '/:pathMatch(.*)*', name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "notfound" */ './views/PageNotFound.vue'),
    meta: { layout: 'public', label: 'Page Not Found', zone: 'public' }
  },
)

export const router = createRouter({
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
  },
  // history: createWebHashHistory(),
  // change to html5 history (don't like seeing that # from hash history
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

