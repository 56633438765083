import axios from 'axios'
import authHelper from '@/helpers/auth-helper'
import {apiUrlGet} from '@/helpers/api-helper'

const debug = false
const PX = 'Axios helper --- '

/**
 * Set or clear the Authorization header AND emit appropriate log in or log out events.
 *
 * @param token
 */
export const notYetSetAuthHeader = (token) => {
  const newHash = authHelper.hashToken(token)
  const currentAuth = axios.defaults.headers['Authorization']
  let existingHash = currentAuth ? authHelper.hashToken(currentAuth.replace('Bearer ', '')) : undefined
  if (token) {
    if (existingHash === newHash) {
      if (debug) console.log(PX + 'new is same as old so do nothing')
    } else {
      if (debug) console.log(PX + 'old and new are different', existingHash, newHash)
      // Note that the token changes when the user selects a new Activity from any Course.
      // As well, of course, when the user enters via LTI
      if(existingHash) {
        if (debug) console.log(PX + 'emit logout event')
        axios.defaults.headers['Authorization'] = null
        // EventBus.$emit(USER_LOGOUT_EVENT)
      }
      if (debug) console.log(PX + 'set axios Authorization header to the new token', newHash)
      axios.defaults.headers['Authorization'] = `Bearer ${token}`
      if (debug) console.log(PX + 'emit login event')
      // EventBus.$emit(USER_LOGIN_EVENT)
    }
  } else {
    axios.defaults.headers['Authorization'] = null
    if(existingHash) {
      if (debug) console.log(PX + 'emit logout event')
      // EventBus.$emit(USER_LOGOUT_EVENT)
    }
  }
}

export const axiosPut = async (queryUrl, bodyData) => {
  let url = apiUrlGet() + (queryUrl.startsWith('/') ? '' : '/') + queryUrl
  console.log('Axios put url ', url, bodyData)
  return await axios.put(url, bodyData)
}

export const axiosPost = async (queryUrl, bodyData) => {
  console.log('Axios post with ', queryUrl, bodyData)
  let url = apiUrlGet() + (queryUrl.startsWith('/') ? '' : '/') + queryUrl
  console.log('Axios put url ', url)
  return await axios.post(url, bodyData)
}

export const axiosGet = async (queryUrl, config) => {
  console.log('Axios get with ', queryUrl)
  let url = apiUrlGet() + (queryUrl.startsWith('/') ? '' : '/') + queryUrl
  console.log('Axios get url ', url)
  return await axios.get(url, config).catch(err => {
    console.error('axios get error', err.response.data)
  })
}
