import InstoreHelper from './instoreHelper'
const API = 'visits'
const debug = false
const VISIT_ID = 'visitId'

const state = {
  sVisitData: {},
  _visitId: undefined,
}

const getters = {
  role: state => {
    return state.sVisitData.role
  },
  lastUpdateDate: state => {
    // unlike other models this one's update field is called lastVisitDate
    return state.sVisitData.lastVisitDate
  },
  visitData: state => state.sVisitData,
  visitId: state => state._visitId
}

const actions = {
  initialize: function ({ commit }) {
    commit('initialize')
  },
  async clearVisitData (context) {
    context.commit('setVisitData', {})
    context.commit('setVisitId', undefined)
  },
  loadVisitRecord (context) {
    const visitId = context.getters.visitId
    if (!visitId) {
      console.error('Visit error loading record. Must set visitId first!')
      return
    }
    let url = 'get/' + visitId
    if (debug) console.log('loadVisit api call ', url)
    return InstoreHelper.getRequest(context, API, url).then(response => {
      let visit = response.data ? response.data.visit : undefined
      if (!visit) {
        throw new Error('ERROR No visit information for ' + visitId)
      }
      if (debug) console.log('loadVisit what is the visitInfo? ', visit)
      context.commit('setVisitData', visit)
    })
  },
}

const mutations = {
  initialize: function (state) {
    state._visitId = localStorage.getItem(VISIT_ID)
  },
  setVisitData: (state, info) => {
    if(debug) console.log('visit store setVisitData ', info)
    state.sVisitData = info
  },
  setVisitId: (state, id) => {
    if(debug) console.log('setVisitId ', id)
    // This value needs to survive a browser refresh so make the source of truth the session storage
    if (id) {
      localStorage.setItem(VISIT_ID, id)
    } else {
      localStorage.removeItem(VISIT_ID)
    }
    state._visitId = id
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
